export * from '@oracle-cx-commerce/react-widgets';

/* Liv Components Web */
export const MktpBaseComponent = () => import('./web/widgets/common/mktp-base-component');
/* Liv Components Web */

/* Liv Components Cart Web */
export const MktpCartHeader = () => import('./web/widgets/cart/mktp-cart-header');
export const MktpCartFooter = () => import('./web/widgets/cart/mktp-cart-footer');
export const MktpCartBaseComponent = () => import('./web/widgets/cart/mktp-cart-base-component');
export const MktpCart = () => import('./web/widgets/cart/mktp-cart');
/* Liv Components Cart Web */

/* Liv Components Fastcheckout Web */
export const MktpFastCheckout = () => import('./web/widgets/fastcheckout/mktp-fastcheckout');
/* Liv Components Fastcheckout Web */

/* Liv Components Product Page Web */
export const MktpProductDetails = () => import('./web/widgets/product/mktp-product-details');
export const MktpProductDetailsAdditionalInfos = () =>
  import('./web/widgets/product/mktp-product-details-additional-infos');
export const MktpGiftCardProductDetails = () => import('./web/widgets/product/mktp-giftcard-product-details');
export const MktpProductDetailsAdditionalInfosGiftCard = () =>
  import('./web/widgets/product/mktp-product-details-additional-infos-giftcard');

/* Liv Components Product Page Web */

/* Liv Components wishlist Page Web */
export const MktpWishlist = () => import('./web/widgets/wishlist/mktp-wishlist');
/* Liv Components wishlist Page Web */

/* Liv Components Checkout Web */
export const MktpCartCheckoutBaseComponent = () => import('./web/widgets/checkout/mktp-cart-checkout-base-component');
export const MktpCheckout = () => import('./web/widgets/checkout/mktp-checkout');
export const MktpCheckoutAlertInfo = () => import('./web/widgets/common/mktp-checkout-alert-info');
export const MktpCheckoutPaymentList = () => import('./web/widgets/checkout/mktp-checkout-payment-list');
export const MktpCheckoutPaymentListLite = () => import('./web/widgets/checkout/mktp-checkout-payment-list-lite');
export const MktpAddressPage = () => import('./web/widgets/checkout/mktp-address-page');
export const MktpAddCreditCard = () => import('./web/widgets/checkout/mktp-checkout-add-credit-card');
export const MktpCheckoutOrder = () => import('./web/widgets/checkout/mktp-checkout-order');
export const MktpFastCheckoutOrder = () => import('./web/widgets/fastcheckout/mktp-fast-checkout-order');
/* Liv Components Checkout Web */

/* Liv Components Session Web */
export const MktpLoginButton = () => import('./web/widgets/session/mktp-login-button');
export const MktpLogin = () => import('./web/widgets/session/mktp-login');
export const MktpAutologin = () => import('./web/widgets/session/mktp-autologin');
export const MktpErrorPage = () => import('./web/widgets/session/mktp-error-page');
/* Liv Components Session Web */

/* Liv Components Profile Web */
export const MktpAddress = () => import('./web/widgets/profile/mktp-address');
export const MktpNewAddress = () => import('./web/widgets/profile/mktp-new-address');
/* Liv Components Profile Web */

/* Liv Components Common Web */
export const MktpAllCategories = () => import('./web/widgets/common/mktp-all-categories');
export const MktpHeader = () => import('./web/widgets/common/mktp-header');
export const MktpHeaderV2 = () => import('./web/widgets/common/mktp-header-v2');
export const MktpMenu = () => import('./web/widgets/common/mktp-menu');
export const MktpHeaderSimple = () => import('./web/widgets/common/mktp-header-simple');
export const MktpBanner = () => import('./web/widgets/common/mktp-banner');
export const MktpTapume = () => import('./web/widgets/common/mktp-tapume');
export const MktpBannerFaixa = () => import('./web/widgets/common/mktp-banner-faixa');
export const MktpShowcase = () => import('./web/widgets/common/mktp-showcase');
export const MktpShowcaseAlgolia = () => import('./web/widgets/common/mktp-showcase-algolia');
export const MktpShowcaseSuggestionAlgolia = () => import('./web/widgets/common/mktp-showcase-suggestion-algolia');
export const MktpShowcaseAutomatic = () => import('./web/widgets/common/mktp-showcase-automatic');
export const MktpShowcasePdp = () => import('./web/widgets/common/mktp-showcase-pdp');
export const MktpCategoryShowcase = () => import('./web/widgets/common/mktp-category-showcase');
export const MktpCardCategory = () => import('./web/widgets/common/mktp-card-category');
export const MktpButtonBackTop = () => import('./web/widgets/common/mktp-button-back-top');
export const MktpCategoryList = () => import('./web/widgets/common/mktp-category-list');
export const MktpSignatureFooter = () => import('./web/widgets/common/mktp-signature-footer');
export const MktpFooter = () => import('./web/widgets/common/mktp-footer');
export const MktpMostSearchedFooter = () => import('./web/widgets/common/mktp-most-searched-footer');
export const MktpPartnersSellersShowcase = () => import('./web/widgets/common/mktp-partners-sellers-showcase');
export const MktpPartnerSeller = () => import('./web/widgets/common/mktp-partner-seller');
export const MktpPageShare = () => import('./web/widgets/common/mktp-page-share');
export const MktpBannerMosaico = () => import('./web/widgets/common/mktp-banner-mosaico');
export const MktpHeaderAlgoliaPoc = () => import('./web/widgets/common/mktp-header-algolia-poc');
export const MktpMostSearchedAlgoliaPoc = () => import('./web/widgets/common/mktp-most-searched-algolia-poc');
export const MktpBannerShowcase = () => import('./web/widgets/common/mktp-banner-showcase');
export const MktpStaticBanner = () => import('./web/widgets/common/mktp-static-banner');
export const MktpFooterLinks = () => import('./web/widgets/common/mktp-footer-links');
export const MktpBannerCollectionV2 = () => import('./web/widgets/common/mktp-banner-collection-v2');
export const MktpRedirectButton = () => import('./web/widgets/common/mktp-redirect-button');
export const MktpBannerSizeable = () => import('./web/widgets/common/mktp-banner-sizeable');
export const MktpPriceRangeCard = () => import('./web/widgets/common/mktp-price-range-card');
export const MktpBannerCta = () => import('./web/widgets/common/mktp-banner-cta');
export const MktpEducationalCard = () => import('./web/widgets/common/mktp-educational-card');
export const MktpGenericTitle = () => import('./web/widgets/common/mktp-generic-title');
export const MktpPlayStoreButtons = () => import('./web/widgets/common/mktp-play-store-buttons');
export const MktpFaqList = () => import('./web/widgets/common/mktp-faq-list');
export const MktpPageInformation = () => import('./web/widgets/common/mktp-page-information');
export const MktpConfigurationOptimizely = () => import('./web/widgets/common/mktp-configuration-optimizely');
export const MktpRedirectWebsites = () => import('./web/widgets/common/mktp-redirect-websites');
export const MktpMetaInjector = () => import('./web/widgets/common/mktp-meta-injector');
/* Liv Components Common Web */

/* Liv Containers Web */
export const MktpContainerBaseComponentExample = () =>
  import('./web/containers/common/mktp-container-base-component-example');
export const MktpContainerProduct = () => import('./web/containers/common/mktp-container-product');
export const MktpContainerProductType = () => import('./web/containers/common/mktp-container-product-type');
export const MktpContainerBanner = () => import('./web/containers/common/mktp-container-banner');
export const MktpContainerPartnersSellers = () => import('./web/containers/common/mktp-container-partners-sellers');
export const MktpContainerCategoryShowcase = () => import('./web/containers/common/mktp-container-category-showcase');
export const MktpContainerBannerGrid = () => import('./web/containers/common/mktp-container-banner-grid');
export const MktpContainerCardGrid = () => import('./web/containers/common/mktp-container-card-grid');
export const MktpContainerEducational = () => import('./web/containers/common/mktp-container-educational');
export const MktpContainerPdpSegmentationByProduct = () =>
  import('./web/containers/product/mktp-container-pdp-segmentation-by-product');
export const MktpContainerPdpSegmentationBySeller = () =>
  import('./web/containers/product/mktp-container-pdp-segmentation-by-seller');
/* Liv Containers Web */

/* Liv Components Mobile */
export const MktpAppShowcase = () => import('./app/widgets/common/mktp-app-showcase');
export const MktpAppCategoryItem = () => import('./app/widgets/common/mktp-app-category-item');
export const MktpAppCategoryGridItem = () => import('./app/widgets/common/mktp-app-category-grid-item');
export const MktpAppCollectionResultList = () => import('./app/widgets/collection/mktp-app-collection-result-list');
export const MktpAppRedirectText = () => import('./app/widgets/common/mktp-app-redirect-text');
export const MktpAppOnboardingItem = () => import('./app/widgets/common/mktp-app-onboarding-item');
export const MktpAppHeader = () => import('./app/widgets/common/mktp-app-header');
export const MktpAppImage = () => import('./app/widgets/common/mktp-app-image');
export const MktpAppTitle = () => import('./app/widgets/common/mktp-app-title');
export const MktpAppPartner = () => import('./app/widgets/common/mktp-app-partner');
export const MktpAppFaqCardHelp = () => import('./app/widgets/common/mktp-app-faq-card-help');
export const MktpAppFaqAccordionHelp = () => import('./app/widgets/common/mktp-app-faq-accordion-help');
export const MktpAppFaqSubjectDetail = () => import('./app/widgets/common/mktp-app-faq-subject-detail');
export const MktpAppSearchCategoryItem = () => import('./app/widgets/common/mktp-app-search-category-item');
export const MktpAppBannerItem = () => import('./app/widgets/common/mktp-app-banner-item');
export const MktpAppBigBanner = () => import('./app/widgets/common/mktp-app-big-banner');
export const MktpAppCardItem = () => import('./app/widgets/common/mktp-app-card-item');
export const MktpAppMultiBannerItem = () => import('./app/widgets/common/mktp-app-multi-banner-item');
export const MktpAppFaqSubjectCards = () => import('./app/widgets/common/mktp-app-faq-subject-cards');
export const MktpAppStaticBanner = () => import('./app/widgets/common/mktp-app-static-banner');
export const MktpAppBannerCountdown = () => import('./app/widgets/common/mktp-app-banner-countdown');
export const MktpAppRedirectButton = () => import('./app/widgets/common/mktp-app-redirect-button');
export const MktpAppBannerCta = () => import('./app/widgets/common/mktp-app-banner-cta');
export const MktpAppPageSharing = () => import('./app/widgets/common/mktp-app-page-sharing');
export const MktpAppCollections = () => import('./app/widgets/collection/mktp-app-collections');
export const MktpAppEducationalCard = () => import('./app/widgets/common/mktp-app-educational-card');
export const MktpAppSmallBanner = () => import('./app/widgets/common/mktp-app-small-banner');
export const MktpAppStoreButtons = () => import('./app/widgets/common/mktp-app-store-buttons');
export const MktpAppCardPriceRange = () => import('./app/widgets/common/mktp-app-card-price-range');
export const MktpAppShowcaseSuggestions = () => import('./app/widgets/common/mktp-app-showcase-suggestions');
/* Liv Components Mobile */

/* Liv Components Search Web */
export const MktpDynamicProductListingContainer = () =>
  import('./web/containers/search/mktp-dynamic-product-listing-container');
export const MktpProductList = () => import('./web/widgets/search/mktp-product-list');
export const MktpPLPBanner = () => import('./web/widgets/search/mktp-plp-banner');
export const MktpPartnerBanner = () => import('./web/widgets/search/mktp-partner-banner');
export const MktpCollectionsBanner = () => import('./web/widgets/search/mktp-collections-banner');
export const MktpProductListAlgolia = () => import('./web/widgets/search/mktp-product-list-algolia');
export const MktpProductListAlgoliaV2 = () => import('./web/widgets/search/mktp-product-list-algolia-v2');
/* Liv Components Search Web */

/* Liv Containers Mobile */
export const MktpAppContainerImageCarousel = () => import('./app/containers/common/mktp-app-container-image-carousel');
export const MktpAppContainerOnboarding = () => import('./app/containers/common/mktp-app-container-onboarding');
export const MktpAppContainerCategory = () => import('./app/containers/common/mktp-app-container-category');
export const MktpAppContainerCategoryGrid = () => import('./app/containers/common/mktp-app-container-category-grid');
export const MktpAppContainerPartner = () => import('./app/containers/common/mktp-app-container-partner');
export const MktpAppContainerLargeBannerCarousel = () =>
  import('./app/containers/common/mktp-app-container-large-banner-carousel');
export const MktpAppContainerBannerCountdownCarousel = () =>
  import('./app/containers/common/mktp-app-container-banner-countdown-carousel');
export const MktpAppContainerSmallBannerList = () =>
  import('./app/containers/common/mktp-app-container-small-banner-list');
export const MktpAppContainerEducationalBankingPartners = () =>
  import('./app/containers/common/mktp-app-container-educational-banking-partners');
export const MktpAppContainerBannerEducational = () =>
  import('./app/containers/common/mktp-app-container-banner-educational');
export const MktpAppContainerCarouselCard = () => import('./app/containers/common/mktp-app-container-carousel-card');
export const MktpAppContainerEducacionalGatherPoints = () =>
  import('./app/containers/common/mktp-app-container-educacional-gather-points');
export const MktpAppContainerCategoryList = () => import('./app/containers/common/mktp-app-container-category-list');
export const MktpAppContainerEducationalList = () =>
  import('./app/containers/common/mktp-app-container-educational-list');
export const MktpAppContainerBannerGrid = () => import('./app/containers/common/mktp-app-container-banner-grid');
export const MktpAppContainerGridShowcase = () => import('./app/containers/common/mktp-app-container-grid-showcase');
/* Liv Containers */
